<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row class="pa-0 ma-0 mb-5">
      <v-col cols="12" sm="12" md="12">
        <h1>Reportes de Ventas</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" sm="4">
        <v-select
          v-model="selectedCities"
          :items="cities"
          :rules="[rules.required]"
          prepend-icon="fa-city"
          label="Seleccionar ciudad"
          item-text="name"
          :loading="formLoading"
          item-value=".key"
          aria-autocomplete="false"
          autocomplete="off"
          multiple
          filled
          rounded
          clearable
        >
        </v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value="selectedDate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="selectedDate"
              label="Filtrar por fecha"
              prepend-icon="fa-calendar-alt"
              readonly
              filled
              rounded
              :disabled="formLoading"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="selectedDate"
            range
            :loading="formLoading"
            :rules="[rules.required]"
            :title-date-format="title"
            scrollable
            :max="today"
          >
            <v-row no-gutters style="flex-wrap: nowrap">
              <v-btn
                class="flex-grow-1 flex-shrink-0"
                large
                rounded
                outlined
                color="primary"
                @click="modal = false"
                >Cancelar</v-btn
              >
              <v-btn
                class="flex-grow-1 flex-shrink-0"
                large
                rounded
                color="primary"
                @click="$refs.dialog.save(selectedDate)"
              >
                Aceptar
              </v-btn>
            </v-row>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col cols="4" md="4">
        <v-select
          v-model="tagsToSearch"
          :items="getSearchableTags"
          prepend-icon="fa-filter"
          label="Filtrar por estado"
          item-text="name"
          item-value="value"
          multiple
          aria-autocomplete="false"
          autocomplete="off"
          filled
          rounded
          :loading="formLoading"
          :allow-overflow="false"
          clearable
        >
        </v-select>
      </v-col>
      <v-col cols="4" sm="4">
        <v-select
          v-model="selectedOrderTypes"
          :items="getOrderTypes"
          prepend-icon="fa-city"
          label="Seleccionar tipo de orden"
          item-text="name"
          item-value="value"
          aria-autocomplete="false"
          autocomplete="off"
          multiple
          :loading="formLoading"
          filled
          rounded
          :allow-overflow="false"
          clearable
        >
        </v-select>
      </v-col>

      <v-col cols="4" sm="4">
        <v-select
          v-model="selectedPaymentMethods"
          :items="paymentMethods"
          prepend-icon="fa-credit-card"
          label="Seleccionar por método de pago"
          item-text="name"
          item-value="paymentType"
          aria-autocomplete="false"
          autocomplete="off"
          multiple
          filled
          rounded
          :loading="formLoading"
          :allow-overflow="false"
          clearable
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row class="end">
      <v-col cols="12" sm="4" md="4">
        <v-btn
          @click="generate"
          :loading="formLoading"
          block
          color="primary"
          height="50"
          >Generar</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "cashier-reports",

  data() {
    return {
      formLoading: true,
      snackbarText: "",
      snackbar: false,
      modal: false,
      selectedPaymentMethods: [],
      selectedCities: [],
      cities: [],
      paymentMethods: [],
      orderTypes: [
        { name: "Regular", value: "regular" },
        { name: "Pickup", value: "pickUp" },
        { name: "Mandaditos", value: "errand" },
        { name: "ATM", value: "atm" },
        { name: "Recargas Electrónicas", value: "airTime" },
      ],
      selectedOrderTypes: [],
      selectedBusinesses: [],
      businesses: [],
      selectedDate: [],
      tagsToSearch: [],
      searchableTags: [
        { name: "Colocada", value: "placed", color: "lime darken-1" },
        { name: "Acepatada", value: "accepted", color: "green" },
        { name: "Chavo Camino al comercio", value: "enRoute", color: "amber" },
        {
          name: "Chavo Camino al cliente",
          value: "onDelivery",
          color: "orange",
        },
        { name: "Entregadas", value: "delivered", color: "cyan" },
        { name: "Completadas", value: "completed", color: "blue" },
      ],

      rules: {
        required: (value) => !!value || "Obligatorio",
      },
    };
  },

  computed: {
    ...mapState(["user"]),
    today() {
      return new Date().toISOString().substr(0, 10);
    },

    getSearchableTags() {
      if (this.user.orderStatus && this.user.orderStatus.length) {
        return this.searchableTags.filter((i) =>
          this.user.orderStatus.includes(i.value)
        );
      } else {
        return this.searchableTags;
      }
    },

    getOrderTypes() {
      if (this.user.orderType && this.user.orderType.length) {
        return this.orderTypes.filter((i) =>
          this.user.orderType.includes(i.value)
        );
      } else {
        return this.orderTypes;
      }
    },
  },

  methods: {
    title() {
      return "Selecciona las fechas a filtrar";
    },
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },

    async generate() {
      if (!this.selectedDate || !this.selectedDate.length) {
        this.snackbarText = "Debe seleccionar un rango de fechas.";
        this.snackbar = true;

        return;
      }

      let token = await this.getSectionToken();

      let data = {
        startDate: this.selectedDate[0],
        endDate: this.selectedDate[1],
        paymentMethods: this.selectedPaymentMethods,
        orderStatus: this.tagsToSearch,
        cities: this.selectedCities,
        orderTypes: this.selectedOrderTypes,
      };
      this.formLoading = true;

      axios
        .post(`${process.env.VUE_APP_FUNCTIONS_URL}/httpCashierReports`, data, {
          headers: { Authorization: `Basic ${token}` },
          responseType: "blob",
        })
        .then((response) => {
          this.formLoading = false;
          const { data, headers } = response;
          const fileName = `Reporte ${this.selectedDate[0]} al ${this.selectedDate[1]}.xlsx`;

          const blob = new Blob([data], { type: headers["content-type"] });
          let dom = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          dom.href = url;
          dom.download = decodeURI(fileName);
          dom.style.display = "none";
          document.body.appendChild(dom);
          dom.click();
          dom.parentNode.removeChild(dom);
          window.URL.revokeObjectURL(url);
          // this.$emit("cancel");
          this.formLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.formLoading = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
  },

  async mounted() {
    this.$store.state.visibleSearch = false;

    await this.$binding(
      "cities",
      db
        .collection("cities")
        .where(fb.firestore.FieldPath.documentId(), "in", this.user.cities)
    );

    await this.$binding(
      "paymentMethods",
      db
        .collection("paymentGateways")
        .where(
          fb.firestore.FieldPath.documentId(),
          "in",
          this.user.paymentGateways
        )
    );

    this.formLoading = false;
  },
};
</script>


<style>
.end {
  display: flex;
  justify-content: flex-end;
}
</style>